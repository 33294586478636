import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import beerStyles from "./beer.module.css"

const BeerPage = ({ data }) => {
    const allBeers = data.allBeers.nodes;
    const beerMenuNames = data.menu.frontmatter.beer
    let beerMenu = []

    beerMenuNames.forEach(menu => {
      beerMenu.push(allBeers.find(beer => beer.frontmatter.title === menu.name))
    });

    return (
    <Layout>
        <SEO title="Beer" />
        <div className={beerStyles.backgroundColor}></div>
        <h1 className={beerStyles.title}>BEERS ON TAP</h1>
        <Img fluid={data.threeBeersImage.childImageSharp.fluid} alt="Light, amber, and dark beers." className={beerStyles.threeBeersImage} />
        {/* <a href="https://stodgybrewing.square.site/"><div className={beerStyles.orderButton}>ORDER FOR PICKUP</div></a> */}
        {beerMenu.map(beer => {
            return (
                <div className={beerStyles.beer} key={beer.frontmatter.title}>
                    <div className={beerStyles.beerTitle}>
                        <h2>{beer.frontmatter.title}</h2>
                        <p>{beer.frontmatter.abv}% ABV</p>
                    </div>
                    <div className={beerStyles.beerContent}>
                        <p className={beerStyles.ingredients}>{beer.frontmatter.ingredients}</p>
                        <p>{beer.frontmatter.description}</p>
                    </div>
                </div>
            )
        })}
        {/* <div className={beerStyles.beer}>
            <div className={beerStyles.beerTitle}>
                <h2>Non-Alcoholic</h2>
            </div>
            <div className={beerStyles.beerContent}>
                <p>We have Non-Alcoholic Beverages that often include kombucha, root beer, nitro cold brew coffee, bubbly water, and juice boxes.</p>
            </div>
        </div> */}
        <div className={beerStyles.nonalcoholic}>
          <p>** We have Non-Alcoholic Beverages that often include kombucha, root beer, and bubbly water. **</p>
        </div>
    </Layout>
    )
}

export const query = graphql`
  query {
    threeBeersImage: file(relativePath: { eq: "3-beers.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    menu: markdownRemark(frontmatter: {templateKey: {eq: "beerPage"}}) {
      frontmatter {
        beer {
          name
        }
      }
    }
    allBeers: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "beer"}}}) {
      nodes {
        frontmatter {
          title
          ingredients
          description
          abv
        }
      }
    }
  }
`

export default BeerPage
